import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaSegmentedControlDefaultVariants = {}
const aromaSegmentedControlCompoundVariants = []

const aromaSegmentedControlSlotNames = [
  [
    "root",
    "aroma-segmented-control__root"
  ],
  [
    "item",
    "aroma-segmented-control__item"
  ],
  [
    "text",
    "aroma-segmented-control__text"
  ],
  [
    "indicator",
    "aroma-segmented-control__indicator"
  ]
]
const aromaSegmentedControlSlotFns = /* @__PURE__ */ aromaSegmentedControlSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaSegmentedControlDefaultVariants, getSlotCompoundVariant(aromaSegmentedControlCompoundVariants, slotName))])

const aromaSegmentedControlFn = memo((props = {}) => {
  return Object.fromEntries(aromaSegmentedControlSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaSegmentedControlVariantKeys = [
  "fullWidth"
]
const getVariantProps = (variants) => ({ ...aromaSegmentedControlDefaultVariants, ...compact(variants) })

export const aromaSegmentedControl = /* @__PURE__ */ Object.assign(aromaSegmentedControlFn, {
  __recipe__: false,
  __name__: 'aromaSegmentedControl',
  raw: (props) => props,
  variantKeys: aromaSegmentedControlVariantKeys,
  variantMap: {
  "fullWidth": [
    "true"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaSegmentedControlVariantKeys)
  },
  getVariantProps
})