import { Event } from '@bugsnag/js'
import { StripeError } from '@stripe/stripe-js'

export default function onStripeError(error: StripeError) {
  return (event: Event) => {
    event.addMetadata('stripe', {
      'setup_intent.id': error.setup_intent?.id,
      'error.code': error.code,
      'error.message': error.message,
      'error.type': error.type
    })

    if (
      error.code &&
      [
        'balance_insufficient',
        'incomplete_number',
        'incomplete_cvc',
        'expired_card',
        'incorrect_number',
        'incomplete_expiry'
      ].includes(error.code)
    ) {
      return false
    }
    return true
  }
}
