import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaEmptyStateDefaultVariants = {
  "size": "medium"
}
const aromaEmptyStateCompoundVariants = []

const aromaEmptyStateSlotNames = [
  [
    "root",
    "aroma-empty-state__root"
  ],
  [
    "content",
    "aroma-empty-state__content"
  ],
  [
    "art",
    "aroma-empty-state__art"
  ],
  [
    "heading",
    "aroma-empty-state__heading"
  ],
  [
    "motivation",
    "aroma-empty-state__motivation"
  ]
]
const aromaEmptyStateSlotFns = /* @__PURE__ */ aromaEmptyStateSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaEmptyStateDefaultVariants, getSlotCompoundVariant(aromaEmptyStateCompoundVariants, slotName))])

const aromaEmptyStateFn = memo((props = {}) => {
  return Object.fromEntries(aromaEmptyStateSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaEmptyStateVariantKeys = [
  "size",
  "function"
]
const getVariantProps = (variants) => ({ ...aromaEmptyStateDefaultVariants, ...compact(variants) })

export const aromaEmptyState = /* @__PURE__ */ Object.assign(aromaEmptyStateFn, {
  __recipe__: false,
  __name__: 'aromaEmptyState',
  raw: (props) => props,
  variantKeys: aromaEmptyStateVariantKeys,
  variantMap: {
  "size": [
    "small",
    "medium",
    "large"
  ],
  "function": [
    "Guide",
    "TaskCompleted",
    "Success",
    "Error",
    "Loading",
    "Information"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaEmptyStateVariantKeys)
  },
  getVariantProps
})