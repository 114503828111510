import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaDrawerDefaultVariants = {
  "size": "medium"
}
const aromaDrawerCompoundVariants = [
  {
    "size": "full",
    "stackedActions": true,
    "css": {
      "footer": {
        "flexDirection": "column-reverse"
      }
    }
  }
]

const aromaDrawerSlotNames = [
  [
    "content",
    "aroma-drawer__content"
  ],
  [
    "backdrop",
    "aroma-drawer__backdrop"
  ],
  [
    "positioner",
    "aroma-drawer__positioner"
  ],
  [
    "closeTrigger",
    "aroma-drawer__closeTrigger"
  ],
  [
    "header",
    "aroma-drawer__header"
  ],
  [
    "body",
    "aroma-drawer__body"
  ],
  [
    "footer",
    "aroma-drawer__footer"
  ]
]
const aromaDrawerSlotFns = /* @__PURE__ */ aromaDrawerSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaDrawerDefaultVariants, getSlotCompoundVariant(aromaDrawerCompoundVariants, slotName))])

const aromaDrawerFn = memo((props = {}) => {
  return Object.fromEntries(aromaDrawerSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaDrawerVariantKeys = [
  "size",
  "stackedActions"
]
const getVariantProps = (variants) => ({ ...aromaDrawerDefaultVariants, ...compact(variants) })

export const aromaDrawer = /* @__PURE__ */ Object.assign(aromaDrawerFn, {
  __recipe__: false,
  __name__: 'aromaDrawer',
  raw: (props) => props,
  variantKeys: aromaDrawerVariantKeys,
  variantMap: {
  "size": [
    "full",
    "small",
    "medium",
    "large"
  ],
  "stackedActions": [
    "true"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaDrawerVariantKeys)
  },
  getVariantProps
})