import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaDropdownDefaultVariants = {
  "size": "small"
}
const aromaDropdownCompoundVariants = []

const aromaDropdownSlotNames = [
  [
    "label",
    "aroma-dropdown__label"
  ],
  [
    "positioner",
    "aroma-dropdown__positioner"
  ],
  [
    "trigger",
    "aroma-dropdown__trigger"
  ],
  [
    "indicator",
    "aroma-dropdown__indicator"
  ],
  [
    "clearTrigger",
    "aroma-dropdown__clearTrigger"
  ],
  [
    "item",
    "aroma-dropdown__item"
  ],
  [
    "itemText",
    "aroma-dropdown__itemText"
  ],
  [
    "itemIndicator",
    "aroma-dropdown__itemIndicator"
  ],
  [
    "itemGroup",
    "aroma-dropdown__itemGroup"
  ],
  [
    "itemGroupLabel",
    "aroma-dropdown__itemGroupLabel"
  ],
  [
    "list",
    "aroma-dropdown__list"
  ],
  [
    "content",
    "aroma-dropdown__content"
  ],
  [
    "root",
    "aroma-dropdown__root"
  ],
  [
    "control",
    "aroma-dropdown__control"
  ],
  [
    "valueText",
    "aroma-dropdown__valueText"
  ]
]
const aromaDropdownSlotFns = /* @__PURE__ */ aromaDropdownSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaDropdownDefaultVariants, getSlotCompoundVariant(aromaDropdownCompoundVariants, slotName))])

const aromaDropdownFn = memo((props = {}) => {
  return Object.fromEntries(aromaDropdownSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaDropdownVariantKeys = [
  "size",
  "status"
]
const getVariantProps = (variants) => ({ ...aromaDropdownDefaultVariants, ...compact(variants) })

export const aromaDropdown = /* @__PURE__ */ Object.assign(aromaDropdownFn, {
  __recipe__: false,
  __name__: 'aromaDropdown',
  raw: (props) => props,
  variantKeys: aromaDropdownVariantKeys,
  variantMap: {
  "size": [
    "small",
    "medium"
  ],
  "status": [
    "error",
    "warning"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaDropdownVariantKeys)
  },
  getVariantProps
})