import { memo, splitProps } from '../helpers.js';
import { createRecipe, mergeRecipes } from './create-recipe.js';

const aromaSkeletonFn = /* @__PURE__ */ createRecipe('aroma-skeleton', {
  "shape": "square"
}, [])

const aromaSkeletonVariantMap = {
  "shape": [
    "shape",
    "square",
    "pill"
  ]
}

const aromaSkeletonVariantKeys = Object.keys(aromaSkeletonVariantMap)

export const aromaSkeleton = /* @__PURE__ */ Object.assign(memo(aromaSkeletonFn.recipeFn), {
  __recipe__: true,
  __name__: 'aromaSkeleton',
  __getCompoundVariantCss__: aromaSkeletonFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: aromaSkeletonVariantKeys,
  variantMap: aromaSkeletonVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, aromaSkeletonVariantKeys)
  },
  getVariantProps: aromaSkeletonFn.getVariantProps,
})